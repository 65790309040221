<template>
<div v-for="item in tuiguang" :key="item">


  <div style="background-color: #fff;padding-bottom:10px;"  v-for="list in item.con" :key="list">
    <div class="head_title" style="letter-spacing: 1.5;"> {{ list.con1 }}</div>
    <div style="font-size:14px;padding:15px 0px 10px 10px;border-bottom:1px solid #E3E4E5;"><span class="ndate">{{ list.con2 }}</span>&nbsp;&nbsp;&nbsp;&nbsp;{{ list.con3 }}</div>
    <div style="margin:8px 10px;">
        <span style="display: inline-block;width:30px;height:30px;float:left;"><img :src="list.con4" alt="" style="max-width: 30px;border-radius: 50%;"></span>
        <span style="width:100%;height:30px;line-height: 30px;font-weight:bold;padding-left:10px;color:rgb(96, 127, 166);font-size:14px;">{{ list.con5 }}</span>
    </div>
    <div class="content">
        <p>{{ list.con6 }}</p>
        <p><span class="cblue">{{ list.con7 }}</span></p>
        <img :src="list.con8" alt="">
        <p>{{ list.con9}}</p>
        <p><span class="cblue">{{ list.con10 }}</span></p>
        <img :src="list.con11" alt="">
        <img :src="list.con12" alt="">
        <p>{{ list.con13 }}</p>
        <img :src="list.con14" alt="">
        <p>{{ list.con15}}<span style="font-size:18px;font-weight:bold;color:red;">{{ list.con16}}</span>{{ list.con17}}</p>
        <img :src="list.con18" alt="">
        <p><span class="cblue">{{ list.con19}}</span></p>
        <p>{{ list.con20}}</p>
        <p>{{ list.con21}}</p>
        <p>{{ list.con22}}<span class="wx_rq zaax-wxh" style="letter-spacing: 0.9px; color: rgb(255, 0, 0);" id="copys" data-id="40">{{ list.con23}}</span>{{ list.con24}}</p>
        <p>
            <img :src="list.con25" alt="" style="max-width:30px;border-radius:50%;float:left;"><span style="text-indent: initial;width:30px;height:30px;float:left;padding-left:5px;width: 60px;">{{ list.con26}}</span>
            <img :src="list.con27" alt="" style="max-width:30px;border-radius:50%;float:left;"><span style="text-indent: initial;width:30px;height:30px;float:left;padding-left:5px;">{{ list.con28}}</span>
        </p>
    </div>
  </div>
  <div class="comment"><br><br><br></div>
 
  <div class="btm_box" style="width:100%;  height:55px;  border-top:1px solid #ccc;  background: white;  position: fixed;  bottom: 0;left:0;z-index: 100000;" v-for="list in item.foot" :key="list">
  <div class="btm_left">
    <div style="float:left;"><img :src="list.foot1" style="height:50px;width:50px;margin-top:2.5px;margin-left:3px;display:inline-block;" alt=""></div>
    <div style="height:55px;float:left;padding-left:10px;">
        <p style="font-size:14px;margin-top:8px;">{{ list.foot2}}</p>
        <p style="font-size:14px;">{{ list.foot3}}<span class="wx_rq zaax-wxh" style="letter-spacing: 0.9px; color: rgb(255, 0, 0);" id="copys" data-id="40">{{ list.foot4}}</span></p>
    </div>
    </div>
  </div>
 
 
 
 </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      tuiguang: null,
    }
  },
  mounted() {
    this.add();
  },
  methods: {
    async add() {
      axios.get('https://api.bf839.com/ad16.json').then((response) => {
        if (response) {
          this.tuiguang = response.data.data.tuiguang;
          console.log('数据获取成功');
        }
        else (
          alert('数据获取失败')
        )
      }
      )
    }
  },

}
</script>

 